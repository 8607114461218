// import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      {/* Navigation Bar (Sticky top) */}
      <nav class="navbar navbar-expand-lg navbar-dark fixed-top">
        <div class="container">
          {/* <!-- Logo with Icon --> */}
          <a class="navbar-brand d-flex align-items-center" href="#">
          <img src="logo.jpg" class="img-fluid rounded shadow logo-main" alt="Logo"/> 
            <i class="fas fa-film fa-2x text-danger me-2 animate__animated animate__rotateIn"></i>
            <span class="logo-text">
              <span class="text-danger fw-bold">FILM CITY</span>
              <span class="text-white">PRODUCTION HOUSE</span>
            </span>
          </a>
          
          {/* <!-- Hamburger Menu (Animated) --> */}
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
            <span class="navbar-toggler-icon"></span>
          </button>
          
          {/* <!-- Navigation Links --> */}
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav ms-auto">
              <li class="nav-item">
                <a class="nav-link animate__animated animate__fadeInDown" href="#about">
                  <span class="nav-link-inner">About</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link animate__animated animate__fadeInDown" href="#services">
                  <span class="nav-link-inner">Services</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link animate__animated animate__fadeInDown" href="#projects">
                  <span class="nav-link-inner">Projects</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link animate__animated animate__fadeInDown" href="#faq">
                  <span class="nav-link-inner">FAQs</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link btn btn-danger mx-2 animate__animated animate__pulse" href="#contact">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* Hero Section (Full-screen banner with a call-to-action) */}
      <section id="hero" class="vh-100 d-flex align-items-center justify-content-center text-center position-relative">
        <div class="hero-overlay"></div>
        
        {/* <!-- Video Background (Add your video file) --> */}
        <video autoPlay muted loop playsInline class="hero-video">
          <source src="home-video.mp4" type="video/mp4"/>
        </video>

        <div class="container text-white position-relative">
          <h1 class="animate__animated animate__fadeInDown display-2 fw-bold mb-4">Crafting Stories, <br/>Building Futures</h1>
          <p class="animate__animated animate__fadeInUp lead mb-4">Cinematic Excellence Meets Digital Innovation</p>
          <a href="#contact" class="btn btn-danger btn-lg animate__animated animate__pulse">Start Your Project</a>
        </div>
      </section>
      {/* About Us (Brief description of the company) */}
      <section id="about" class="py-5">
        <div class="container">
          <div class="row g-5 align-items-center">
            <div class="col-md-6 animate__animated">
              <h2 class="section-title mb-4">About Us</h2>
              <p class="lead">We are a fusion of cinematic artistry and cutting-edge IT innovation.</p>
              <p>With over a decade of experience, we deliver unforgettable visual storytelling and robust digital solutions.</p>
              <button class="btn btn-outline-danger" data-bs-toggle="modal" data-bs-target="#aboutModal">Learn More</button>
            </div>
            <div class="col-md-6 animate__animated">      
              <video autoPlay muted loop playsInline class="img-fluid">
                <source src="about-us.mp4" type="video/mp4"/>
              </video>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- About Modal --> */}
      <div class="modal fade" id="aboutModal" tabindex="-1">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content corporate-modal">
            <div class="modal-body">
              <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"></button>
              <div class="row g-5">
                <div class="col-md-6">
                  <div class="corporate-timeline">
                    <h2 class="corporate-heading">Our Foundation</h2>
                    
                    <div class="timeline-item">
                      <div class="timeline-year">2019</div>
                      <h4>The Genesis</h4>
                      <p>Founded by visionary entrepreneur <strong>Arpan Bom</strong> with a mission to revolutionize digital storytelling through technological innovation.</p>
                    </div>

                    <div class="core-values">
                      <h4 class="values-heading">Core Values</h4>
                      <div class="value-item">
                        <i class="fas fa-lightbulb value-icon"></i>
                        <span>Innovation Through Integration</span>
                      </div>
                      <div class="value-item">
                        <i class="fas fa-crosshairs value-icon"></i>
                        <span>Precision in Execution</span>
                      </div>
                      <div class="value-item">
                        <i class="fas fa-infinity value-icon"></i>
                        <span>Sustainable Creativity</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="leadership-profile">
                    <div class="founder-image">
                      <img src="founder.png" alt="Arpan Bom"/>
                    </div>
                    <h3 class="founder-name">Arpan Bom</h3>
                    <p class="founder-title">Founder & CEO</p>
                    <p class="founder-quote">"We don't just create content - we engineer cultural impact through strategic media architecture."</p>
                  </div>

                  {/* <div class="corporate-milestones">
                    <h4 class="milestones-heading">Key Milestones</h4>
                    <div class="milestone-item">
                      <div class="milestone-icon">
                        <i class="fas fa-rocket"></i>
                      </div>
                      <div class="milestone-content">
                        <h5>2031</h5>
                        <p>Pioneered AI-driven film analytics platform</p>
                      </div>
                    </div>
                    <div class="milestone-item">
                      <div class="milestone-icon">
                        <i class="fas fa-globe"></i>
                      </div>
                      <div class="milestone-content">
                        <h5>2033</h5>
                        <p>Expanded to 12 international markets</p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

              <div class="corporate-philosophy">
                <h4 class="philosophy-heading">
                  <i class="fas fa-chart-network"></i>Strategic Vision
                </h4>
                <p>"Our operational matrix combines cinematic artistry with machine learning precision, creating a new paradigm in media production. Through continuous R&D investment and strategic partnerships, we maintain a 92% client retention rate while pushing creative boundaries."</p>
              </div>

              {/* <div class="modal-cta">
                <button class="btn btn-danger" data-bs-dismiss="modal">
                  Explore Our Capability Matrix <i class="fas fa-arrow-right"></i>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Services Section --> */}
      <section id="services" class="py-5 bg-dark text-white">
        <div class="container">
          <h2 class="section-title text-center mb-5">Our Services</h2>
          <div class="row g-4">
            {/* <!-- Service 1 --> */}
            <div class="col-md-4 animate__animated">
              <div class="service-card text-center p-4 h-100">
                <i class="fas fa-film fa-3x text-danger mb-3"></i>
                <h3>Film Production</h3>
                <p>From scriptwriting to post-production, we create cinematic masterpieces.</p>
                <a href="#" class="text-danger" data-bs-toggle="modal" data-bs-target="#filmModal">Learn More <i class="fas fa-arrow-right"></i></a>
              </div>
            </div>
            {/* <!-- Service 2 --> */}
            <div class="col-md-4 animate__animated">
              <div class="service-card text-center p-4 h-100">
                <i class="fas fa-ad fa-3x text-danger mb-3"></i>
                <h3>Advertisements</h3>
                <p>Captivating campaigns that resonate with audiences globally.</p>
                <a href="#" class="text-danger" data-bs-toggle="modal" data-bs-target="#adModal">Learn More <i class="fas fa-arrow-right"></i></a>
              </div>
            </div>
            {/* <!-- Service 3 --> */}
            <div class="col-md-4 animate__animated">
              <div class="service-card text-center p-4 h-100">
                <i class="fas fa-server fa-3x text-danger mb-3"></i>
                <h3>IT Solutions</h3>
                <p>AI-driven software, cloud solutions, and cybersecurity.</p>
                <a href="#" class="text-danger" data-bs-toggle="modal" data-bs-target="#itModal">Learn More <i class="fas fa-arrow-right"></i></a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Service Modals --> */}
      {/* <!-- Film Production Modal --> */}
      <div class="modal fade" id="filmModal">
        <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="modal-content bg-dark text-white">
            <div class="modal-body p-5">
              <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"></button>
              <div class="row g-5">
                {/* <!-- Process Column --> */}
                <div class="col-md-6" data-aos="fade-right">
                  <h2 class="text-danger mb-4">Film Production Excellence</h2>
                  <div class="process-steps">
                    <div class="step mb-5">
                      <div class="step-icon"><i class="fas fa-lightbulb"></i></div>
                      <h4>Concept Development</h4>
                      <p>Collaborative brainstorming sessions to craft compelling narratives</p>
                    </div>
                    <div class="step mb-5">
                      <div class="step-icon"><i class="fas fa-video"></i></div>
                      <h4>4K Cinematography</h4>
                      <p>Shot on Arri Alexa Mini LF with Master Anamorphic lenses</p>
                    </div>
                    <div class="step">
                      <div class="step-icon"><i class="fas fa-magic"></i></div>
                      <h4>Post-Production</h4>
                      <p>Color grading in DaVinci Resolve and VFX in Nuke</p>
                    </div>
                  </div>
                </div>

                {/* <!-- Tech Column --> */}
                <div class="col-md-6" data-aos="fade-left">
                  <div class="tech-showcase">
                    <h3 class="text-danger mb-4">Technical Mastery</h3>
                    
                    <div class="tech-item mb-4">
                      <div class="d-flex justify-content-between">
                        <span>Color Grading</span>
                        <span>95%</span>
                      </div>
                      <div class="progress">
                        <div class="progress-bar color-grading"></div>
                      </div>
                    </div>
                    
                    <div class="tech-item mb-4">
                      <div class="d-flex justify-content-between">
                        <span>Visual Effects</span>
                        <span>90%</span>
                      </div>
                      <div class="progress">
                        <div class="progress-bar visual-effects"></div>
                      </div>
                    </div>

                    <div class="awards mt-5">
                      <h4 class="text-danger mb-3"><i class="fas fa-trophy"></i> Recent Awards</h4>
                      <ul class="list-unstyled">                        
                        <li class="mb-2">2022 Best National Feature Film Award at the 4th Nepal Cultural International Film Festival</li>                        
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Ad Campaigns Modal --> */}
      <div class="modal fade" id="adModal">
        <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="modal-content bg-dark text-white">
            <div class="modal-body p-5">
              <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"></button>
              <div class="row g-5">
                {/* <!-- Campaign Phases --> */}
                <div class="col-md-6" data-aos="fade-right">
                  <h2 class="text-danger mb-4">Campaign Strategy</h2>
                  <div class="row g-4">
                    <div class="col-6">
                      <div class="phase-card p-3">
                        <div class="phase-icon mb-3">
                          <i class="fas fa-chart-line fa-2x text-danger"></i>
                        </div>
                        <h5>Market Analysis</h5>
                        <p class="small">Audience insights & competitor research</p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="phase-card p-3">
                        <div class="phase-icon mb-3">
                          <i class="fas fa-paint-brush fa-2x text-danger"></i>
                        </div>
                        <h5>Creative Development</h5>
                        <p class="small">Storyboarding & concept art</p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="phase-card p-3">
                        <div class="phase-icon mb-3">
                          <i class="fas fa-film fa-2x text-danger"></i>
                        </div>
                        <h5>Production</h5>
                        <p class="small">Multi-platform content creation</p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="phase-card p-3">
                        <div class="phase-icon mb-3">
                          <i class="fas fa-rocket fa-2x text-danger"></i>
                        </div>
                        <h5>Launch & Analytics</h5>
                        <p class="small">Real-time performance tracking</p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Testimonials --> */}
                <div class="col-md-6" data-aos="fade-left">
                  <h3 class="text-danger mb-4">Client Success Stories</h3>
                  <div class="testimonial-card p-4 mb-4">
                    <blockquote>
                      "Their campaign increased our engagement by 300% in just 2 months!"
                    </blockquote>
                    <div class="client-info">
                      {/* <img src="client1.jpg" class="rounded-circle me-2" width="40" alt="Client"/> */}
                      <span>Ram Thapa, CEO, Picturesque Travel</span>
                    </div>
                  </div>
                  
                  <div class="industries mt-5">
                    <h4 class="text-danger mb-3"><i class="fas fa-globe"></i> Industries Served</h4>
                    <div class="badge-container">
                      <span class="badge bg-danger me-2 mb-2">Fashion</span>
                      <span class="badge bg-danger me-2 mb-2">Tech</span>
                      <span class="badge bg-danger me-2 mb-2">Entertainment</span>
                      <span class="badge bg-danger me-2 mb-2">Education</span>
                      <span class="badge bg-danger me-2 mb-2">Travel & Hospitality</span>
                      <span class="badge bg-danger me-2 mb-2">Finance & Banking</span>
                      <span class="badge bg-danger me-2 mb-2">Real Estate</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- AI & IT Solutions Modal --> */}
      <div class="modal fade" id="itModal">
        <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="modal-content corporate-modal">
            <div class="modal-body">
              <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"></button>
              <div class="row g-5">
                {/* <!-- Left Column --> */}
                <div class="col-md-6">
                  <h2 class="corporate-heading">Technology Solutions</h2>
                  
                  {/* <!-- Web App Section --> */}
                  <div class="tech-category">
                    <h4 class="tech-category-heading">
                      <i class="fas fa-window-maximize"></i> Web Applications
                    </h4>
                    <div class="tech-stack-grid">
                      <div class="tech-card">
                        <i class="fab fa-react"></i>
                        <h5>React.js</h5>
                        <p>Frontend development</p>
                      </div>
                      <div class="tech-card">
                        <i class="fab fa-node-js"></i>
                        <h5>Node.js</h5>
                        <p>Backend services</p>
                      </div>
                      <div class="tech-card">
                        <i class="fas fa-database"></i>
                        <h5>PostgreSQL</h5>
                        <p>Database management</p>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Mobile App Section --> */}
                  <div class="tech-category">
                    <h4 class="tech-category-heading">
                      <i class="fas fa-mobile-alt"></i> Mobile Applications
                    </h4>
                    <div class="tech-stack-grid">
                      <div class="tech-card">
                        <i class="fab fa-swift"></i>
                        <h5>Swift</h5>
                        <p>iOS development</p>
                      </div>
                      <div class="tech-card">
                        <i class="fab fa-android"></i>
                        <h5>Kotlin</h5>
                        <p>Android development</p>
                      </div>
                      <div class="tech-card">
                        <i class="fab fa-react"></i>
                        <h5>React Native</h5>
                        <p>Cross-platform</p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Right Column --> */}
                <div class="col-md-6">
                  {/* <!-- AI Section --> */}
                  <div class="tech-category">
                    <h4 class="tech-category-heading">
                      <i class="fas fa-brain"></i> AI & Machine Learning
                    </h4>
                    <div class="tech-stack-grid">
                      <div class="tech-card">
                        <i class="fab fa-python"></i>
                        <h5>Python</h5>
                        <p>Data science</p>
                      </div>
                      <div class="tech-card">
                        <i class="fas fa-robot"></i>
                        <h5>TensorFlow</h5>
                        <p>Deep learning</p>
                      </div>
                      <div class="tech-card">
                        <i class="fas fa-chart-line"></i>
                        <h5>PyTorch</h5>
                        <p>Neural networks</p>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Cloud Section --> */}
                  <div class="tech-category">
                    <h4 class="tech-category-heading">
                      <i class="fas fa-cloud"></i> Cloud Infrastructure
                    </h4>
                    <div class="tech-stack-grid">
                      <div class="tech-card">
                        <i class="fab fa-aws"></i>
                        <h5>AWS</h5>
                        <p>Cloud services</p>
                      </div>
                      <div class="tech-card">
                        <i class="fab fa-google"></i>
                        <h5>Google Cloud</h5>
                        <p>AI integration</p>
                      </div>
                      <div class="tech-card">
                        <i class="fas fa-server"></i>
                        <h5>Kubernetes</h5>
                        <p>Container orchestration</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Integration Process --> */}
              <div class="integration-process">
                <h4 class="process-heading">
                  <i class="fas fa-cogs"></i> Development Process
                </h4>
                <div class="process-steps">
                  <div class="step">
                    <div class="step-number">1</div>
                    <div class="step-content">
                      <h5>Requirement Analysis</h5>
                      <p>Detailed project scoping and technical planning</p>
                    </div>
                  </div>
                  <div class="step">
                    <div class="step-number">2</div>
                    <div class="step-content">
                      <h5>Prototype Development</h5>
                      <p>Rapid iteration and user testing</p>
                    </div>
                  </div>
                  <div class="step">
                    <div class="step-number">3</div>
                    <div class="step-content">
                      <h5>Deployment & Maintenance</h5>
                      <p>CI/CD pipeline and ongoing support</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End of services modals above*/}
      {/* <!-- FAQs Section --> */}
      <section id="faq" class="py-5 bg-light">
        <div class="container">
          <h2 class="section-title text-center mb-5">Frequently Asked Questions</h2>
          <div class="accordion">
            {/* <!-- FAQ 1 --> */}
            <div class="accordion-item">
              <h3 class="accordion-header">
                <button class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq1">
                  What services do you offer?
                </button>
              </h3>
              <div id="faq1" class="accordion-collapse collapse">
                <div class="accordion-body">
                  We specialize in film production (short films, documentaries), advertisements (TV, digital), and IT solutions (software development, AI).
                </div>
              </div>
            </div>

            {/* <!-- FAQ 2 --> */}
            <div class="accordion-item">
              <h3 class="accordion-header">
                <button class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq2">
                  What industries do you work with?
                </button>
              </h3>
              <div id="faq2" class="accordion-collapse collapse">
                <div class="accordion-body">
                  Entertainment, tech startups, e-commerce, and corporate sectors.
                </div>
              </div>
            </div>

            {/* <!-- FAQ 3 --> */}
            <div class="accordion-item">
              <h3 class="accordion-header">
                <button class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq3">
                  How long does a typical project take?
                </button>
              </h3>
              <div id="faq3" class="accordion-collapse collapse">
                <div class="accordion-body">
                  Film projects: 4-12 weeks. IT solutions: 8-24 weeks, depending on complexity.
                </div>
              </div>
            </div>

            {/* <!-- FAQ 4 --> */}
            <div class="accordion-item">
              <h3 class="accordion-header">
                <button class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq4">
                  Do you handle international projects?
                </button>
              </h3>
              <div id="faq4" class="accordion-collapse collapse">
                <div class="accordion-body">
                  Yes! We’ve worked with clients in North America, Europe, and Asia.
                </div>
              </div>
            </div>

            {/* <!-- FAQ 5 --> */}
            <div class="accordion-item">
              <h3 class="accordion-header">
                <button class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq5">
                  What makes your IT solutions unique?
                </button>
              </h3>
              <div id="faq5" class="accordion-collapse collapse">
                <div class="accordion-body">
                  We combine cinematic storytelling principles with technical development for user-centric software.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>  

      {/* <!-- Projects Section --> */}
      <section id="projects" class="py-5 bg-dark text-white">
        <div class="container">
          <h2 class="section-title text-center mb-5 animate__animated">Featured Projects</h2>
          <div class="row g-4">
            {/* <!-- Project 1 --> */}
            <div class="col-md-4 animate__animated" data-aos="fade-up">
              <div class="project-card">
                <div class="project-image">
                  <img src="project1-full.jpg" class="img-fluid" alt="Cinematic Short Film"/>
                  <div class="project-overlay">
                    <button class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#project1Modal">
                      <i class="fas fa-expand"></i>
                    </button>
                  </div>
                </div>
                <div class="project-info p-3">
                  <h4>Hemjakot</h4>
                  <p>Comedy &bull; 4K Production</p>
                </div>
              </div>
            </div>
            {/* <!-- Project 2 --> */}
            <div class="col-md-4 animate__animated" data-aos="fade-up">
              <div class="project-card">
                <div class="project-image">
                  <img src="project2-cover1.png" class="img-fluid" alt="Nepali Music and Movie Application"/>
                  <div class="project-overlay">
                    <button class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#project2Modal">
                      <i class="fas fa-expand"></i>
                    </button>
                  </div>
                </div>
                <div class="project-info p-3">
                  <h4>Nepali Music and Movie Application</h4>
                  <p>Interactive Application</p>
                </div>
              </div>
            </div>
            {/* <!-- Project 3 --> */}
            <div class="col-md-4 animate__animated" data-aos="fade-up">
              <div class="project-card">
                <div class="project-image">
                  <img src="project3.png" class="img-fluid" alt="Online Library"/>
                  <div class="project-overlay">
                    <button class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#project3Modal">
                      <i class="fas fa-expand"></i>
                    </button>
                  </div>
                </div>
                <div class="project-info p-3">
                  <h4>Online Library</h4>
                  <p>Research Tools</p>
                </div>
              </div>
            </div>
            {/* <!-- Project 4 --> */}
            <div class="col-md-4 animate__animated" data-aos="fade-up">
              <div class="project-card">
                <div class="project-image">
                  <img src="project4.png" class="img-fluid" alt="Driving Test Helper"/>
                  <div class="project-overlay">
                    <button class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#project4Modal">
                      <i class="fas fa-expand"></i>
                    </button>
                  </div>
                </div>
                <div class="project-info p-3">
                  <h4>Driving Test Helper</h4>
                  <p>Pass your exam</p>
                </div>
              </div>
            </div>
            {/* <!-- Project 5 --> */}
            <div class="col-md-4 animate__animated" data-aos="fade-up">
              <div class="project-card">
                <div class="project-image">
                  <img src="project5.png" class="img-fluid" alt="Bilingual Daily Book for Business Houses"/>
                  <div class="project-overlay">
                    <button class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#project5Modal">
                      <i class="fas fa-expand"></i>
                    </button>
                  </div>
                </div>
                <div class="project-info p-3">
                  <h4>Bilingual Daily Book for Business Houses</h4>
                  <p>All-in-One Business App</p>
                </div>
              </div>
            </div>
            {/* <!-- Project 6 --> */}
            <div class="col-md-4 animate__animated" data-aos="fade-up">
              <div class="project-card">
                <div class="project-image">
                  <img src="project6.png" class="img-fluid" alt="Double Trouble"/>
                  <div class="project-overlay">
                    <button class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#project6Modal">
                      <i class="fas fa-expand"></i>
                    </button>
                  </div>
                </div>
                <div class="project-info p-3">
                  <h4>Double Trouble</h4>
                  <p>Must-Watch Movie</p>
                </div>
              </div>
            </div>
            {/* <!-- Repeat for other projects --> */}
          </div>
        </div>
      </section>

      {/* <!-- Projects Modal 1--> */}
      <div class="modal fade" id="project1Modal">
        <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="modal-content bg-dark text-white">
            <div class="modal-body">
              <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"></button>
              <div class="row g-4">
                <div class="col-md-6">
                  <div class="modal-image-container">
                    <img src="project1-full.jpg" class="img-fluid rounded" alt="Full Project"/>
                  </div>
                </div>
                <div class="col-md-6">
                  <h3>Welcome To Hemjaakot</h3>
                  <ul class="tech-list">
                    <li><span class="badge bg-danger">Comedy</span></li>
                    <li><span class="badge bg-danger">Drama</span></li>
                    <li><span class="badge bg-danger">Romance</span></li>
                  </ul>
                  <p>Visual Style

Cinematography: Lush landscapes of Nepal’s hills juxtaposed with intimate, gritty close-ups of village life.

Tone: Warm, nostalgic humor transitions to gripping emotional depth.</p>
                  {/* <a href="#" class="btn btn-outline-danger">View Case Study</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Projects Modal 2--> */}
      <div class="modal fade" id="project2Modal">
        <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="modal-content bg-dark text-white">
            <div class="modal-body">
              <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"></button>
              <div class="row g-4">
                <div class="col-md-6">
                  <div class="modal-image-container">
                    <img src="project2.png" class="img-fluid rounded" alt="Full Project"/>
                  </div>
                </div>
                <div class="col-md-6">
                  <h3>Nepali Music and Movie Application</h3>
                  <ul class="tech-list">
                    <li><span class="badge bg-danger">Music</span></li>
                    <li><span class="badge bg-danger">Movie</span></li>
                    <li><span class="badge bg-danger">Music Video</span></li>
                  </ul>
                  <p>The Nepali Music and Movie Application is a one-stop platform for streaming the best of Nepali entertainment. Designed for music and movie enthusiasts, the app offers a vast library of songs, albums, and films, showcasing the rich cultural heritage and modern creativity of Nepal. With a user-friendly interface, high-quality streaming, and personalized recommendations, users can enjoy their favorite content anytime, anywhere. Whether you're a fan of traditional melodies or contemporary cinema, this app brings the essence of Nepal to your fingertips.</p>
                  {/* <a href="#" class="btn btn-outline-danger">View Case Study</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Projects Modal 3--> */}
      <div class="modal fade" id="project3Modal">
        <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="modal-content bg-dark text-white">
            <div class="modal-body">
              <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"></button>
              <div class="row g-4">
                <div class="col-md-6">
                  <div class="modal-image-container">
                    <img src="project3.png" class="img-fluid rounded" alt="Full Project"/>
                  </div>
                </div>
                <div class="col-md-6">
                  <h3>Online Library</h3>
                  <ul class="tech-list">
                    <li><span class="badge bg-danger">Digital Library</span></li>
                    <li><span class="badge bg-danger">Offline Reading</span></li>
                    <li><span class="badge bg-danger">Book Recommendations</span></li>
                  </ul>
                  <p>The Online Library project is a digital haven for book lovers, researchers, and lifelong learners. It provides access to an extensive collection of books, journals, and educational resources across various genres and disciplines. With advanced search features, customizable reading lists, and offline access, the platform ensures a seamless and enriching reading experience. Whether you're diving into classic literature, exploring academic research, or discovering new authors, this online library is designed to inspire and empower users with knowledge at their fingertips.</p>
                  {/* <a href="#" class="btn btn-outline-danger">View Case Study</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* <!-- Projects Modal 4--> */}
      <div class="modal fade" id="project4Modal">
        <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="modal-content bg-dark text-white">
            <div class="modal-body">
              <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"></button>
              <div class="row g-4">
                <div class="col-md-6">
                  <div class="modal-image-container">
                    <img src="project4.png" class="img-fluid rounded" alt="Driving Test Helper"/>
                  </div>
                </div>
                <div class="col-md-6">
                  <h3>Driving Test Helper</h3>
                  <ul class="tech-list">
                    <li><span class="badge bg-danger">Practice Quizzes</span></li>
                    <li><span class="badge bg-danger">Driving Tips</span></li>
                    <li><span class="badge bg-danger">Interactive Learning</span></li>
                  </ul>
                  <p>The Driving Test Helper is a comprehensive app designed to help users ace their driving tests with confidence. Featuring practice quizzes, step-by-step tutorials, and expert tips, the app covers all aspects of driving theory and practical skills. From mastering parallel parking to understanding road signs, the interactive tools and simulations make learning engaging and effective. With progress tracking and personalized feedback, the Driving Test Helper is the ultimate companion for aspiring drivers, ensuring they are well-prepared to hit the road safely and successfully.</p>
                  {/* <a href="#" class="btn btn-outline-danger">View Case Study</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>          
      
      {/* <!-- Projects Modal 5--> */}
      <div class="modal fade" id="project5Modal">
        <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="modal-content bg-dark text-white">
            <div class="modal-body">
              <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"></button>
              <div class="row g-4">
                <div class="col-md-6">
                  <div class="modal-image-container">
                    <img src="project5.png" class="img-fluid rounded" alt="Bilingual Daily Book for Business Houses"/>
                  </div>
                </div>
                <div class="col-md-6">
                  <h3>Bilingual Daily Book for Business Houses</h3>
                  <ul class="tech-list">
                    <li><span class="badge bg-danger">Business Operations</span></li>
                    <li><span class="badge bg-danger">Business Chat</span></li>
                    <li><span class="badge bg-danger">Business Efficiency</span></li>
                  </ul>
                  <p>The Bilingual Daily Book for Business Houses is an all-in-one solution designed to streamline business operations while bridging language barriers. This innovative tool combines a bilingual phrasebook for seamless communication with essential business functions like a daily ledger, chat system, document management, and PDF reader. Whether you're managing finances, collaborating with international teams, or reviewing contracts, this platform ensures efficiency and clarity in a multilingual business environment. Perfect for global entrepreneurs and businesses, it empowers users to run their operations smoothly while fostering cross-cultural understanding and productivity.</p>
                  {/* <a href="#" class="btn btn-outline-danger">View Case Study</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>   

      {/* <!-- Projects Modal 6--> */}
      <div class="modal fade" id="project6Modal">
        <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="modal-content bg-dark text-white">
            <div class="modal-body">
              <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"></button>
              <div class="row g-4">
                <div class="col-md-6">
                  <div class="modal-image-container">
                    <img src="project6.png" class="img-fluid rounded" alt="Driving Test Helper"/>
                  </div>
                </div>
                <div class="col-md-6">
                  <h3>Double Trouble</h3>
                  <ul class="tech-list">
                    <li><span class="badge bg-danger">Action</span></li>
                    <li><span class="badge bg-danger">Comedy</span></li>
                    <li><span class="badge bg-danger">Thriller</span></li>
                    <li><span class="badge bg-danger">Multi-language dubbed</span></li>
                  </ul>
                  <p>"Double Trouble" is an upcoming action-comedy-thriller film currently in pre-production, with the team finalizing script drafts and preparing for official registration. The movie follows the hilarious misadventures of two unlikely partners who find themselves entangled in a high-stakes heist gone wrong. With a perfect blend of humor, action, and heartwarming moments, the film promises to deliver a rollercoaster of emotions. The vibrant characters, fast-paced plot, and visually stunning set pieces are designed to captivate audiences. As a multi-language film, it aims to reach a global audience, blending cultural nuances with universal appeal. "Double Trouble" is a must-watch cinematic experience that combines laughter, thrills, and unforgettable action.</p>
                  {/* <a href="#" class="btn btn-outline-danger">View Case Study</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>            

      {/* <!-- Contact Section --> */}
      <section id="contact" class="py-5 position-relative">
        <div class="contact-overlay"></div>
        <div class="container position-relative">
          <h2 class="section-title text-center mb-5 animate__animated">Start Your Project</h2>
          <div class="row g-5 justify-content-center">
            {/* <!-- Contact Form --> */}
            <div class="col-md-6 animate__animated" data-aos="fade-right">
              <form id="contactForm" class="contact-form" action="https://formsubmit.co/e98933629be57328721d852e24f726f7" method="POST">
                <input type="hidden" name="_subject" value="New submission!"/>
                <input type="hidden" name="_next" value="https://filmcityproductionhouse.com/"/>
                <div class="form-floating mb-4">
                  <input type="text" class="form-control" id="name" placeholder="Name" name="fullname"/>
                  <label for="name"><i class="fas fa-user me-2"></i>Your Name</label>
                </div>
                <div class="form-floating mb-4">
                  <input type="email" class="form-control" id="email" placeholder="Email" name="email"/>
                  <label for="email"><i class="fas fa-envelope me-2"></i>Email Address</label>
                </div>
                <div class="form-floating mb-4">
                  <textarea class="form-control" id="message" placeholder="Message" name="message"></textarea>
                  <label for="message"><i class="fas fa-comment me-2"></i>Your Vision</label>
                </div>
                <button type="submit" class="btn btn-danger w-100 py-3">
                  Send Message <i class="fas fa-paper-plane ms-2"></i>
                </button>
              </form>
            </div>
            
            {/* <!-- Contact Info --> */}
            <div class="col-md-4 animate__animated" data-aos="fade-left">
              <div class="contact-info-card p-4 rounded">
                <h4 class="text-danger mb-4">Let's Create Magic</h4>
                <ul class="list-unstyled">
                  <li class="mb-4">
                    <i class="fas fa-map-marker-alt fa-2x text-danger me-3"></i>
                    <div>
                      <h5>Studio Address</h5>
                      <p>Biratnagar-3, Nepal</p>
                    </div>
                  </li>
                  <li class="mb-4">
                    <i class="fas fa-phone fa-2x text-danger me-3"></i>
                    <div>
                      <h5>Call Us</h5>
                      <p>+977-981-2389422</p>
                      <p>+977-982-3015072</p>
                    </div>
                  </li>
                  <li>
                    <i class="fas fa-clock fa-2x text-danger me-3"></i>
                    <div>
                      <h5>Working Hours</h5>
                      <p>Mon-Fri: 9AM - 6PM GMT+5:45</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Success Modal --> */}
      <div class="modal fade" id="successModal">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content bg-dark text-white">
            <div class="modal-body text-center p-5">
              <i class="fas fa-check-circle fa-5x text-danger mb-4"></i>
              <h3>Message Sent!</h3>
              <p>We'll get back to you within 24 hours.</p>
            </div>
          </div>
        </div>
      </div>
       
      {/* Footer (Social media links and copyright) */}
      <footer class="footer bg-dark text-white py-5">
        <div class="container">
          <div class="row g-4">
            {/* <!-- Company Info --> */}
            <div class="col-md-4">
              <h4 class="text-danger mb-3">FILM CITY PRODUCTION HOUSE</h4>
              <p>Where creativity meets technology to shape tomorrow.</p>
              <div class="social-icons">
                {/* <a href="#" class="text-white me-3"><i class="fab fa-facebook"></i></a>                 */}
                {/* <a href="#" class="text-white"><i class="fab fa-youtube"></i></a> */}
              </div>
            </div>

            {/* <!-- Quick Links --> */}
            <div class="col-md-2">
              <h5 class="text-danger mb-3">Quick Links</h5>
              <ul class="list-unstyled">
                <li><a href="#about" class="text-white footer-link">About Us</a></li>
                <li><a href="#services" class="text-white footer-link">Services</a></li>
                <li><a href="#portfolio" class="text-white footer-link">Portfolio</a></li>
                <li><a href="#faq" class="text-white footer-link">FAQs</a></li>
              </ul>
            </div>

            {/* <!-- Contact Info --> */}
            <div class="col-md-3">
              <h5 class="text-danger mb-3">Contact Us</h5>
              <ul class="list-unstyled">
                <li class="mb-2"><i class="fas fa-envelope me-2 text-danger"></i>info@filmcityproductionhouse.com, filmcityproductionhouse@gmail.com</li>
                <li class="mb-2"><i class="fas fa-phone me-2 text-danger"></i>+977-981-2389422</li>
                <li><i class="fas fa-map-marker-alt me-2 text-danger"></i>Biratnagar-3, Nepal</li>
              </ul>
            </div>

            {/* <!-- Newsletter --> */}
            <div class="col-md-3">
              <h5 class="text-danger mb-3">Newsletter</h5>
              <ul class="list-unstyled">
                <li class="mb-2"><i class="fas fa-envelope me-2 text-danger"></i>Comming Soon !</li>                
              </ul>
              {/* <form>
                <div class="mb-3">
                  <input type="email" class="form-control bg-transparent text-white" placeholder="Enter your email"/>
                </div>
                <button class="btn btn-danger w-100">Subscribe</button>
              </form> */}

            </div>
          </div>

          {/* <!-- Copyright --> */}
          <div class="text-center mt-5 pt-3 border-top border-secondary">
            <p class="mb-0">&copy; 2025 Film City Production House Pvt. Ltd. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
